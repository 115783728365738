<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import VChart from 'vue-echarts'

use([CanvasRenderer, PieChart, TooltipComponent, LegendComponent])

export default {
  name: 'EchartPie',
  components: {
    VChart,
  },
  props: {
    chartInfo: Object,
  },
  provide: {},
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item',
          formatter(params) {
            const { name, value, unit } = params.data
            return `<div style="padding: 0 4px; font-size: 12px; color: #000"><div style="display: inline-block; width: 10px; height: 10px; margin-right: 6px; border-radius: 50%; background: ${params.color};"></div>${name}<div style="padding-left: 16px;">${value}</div><b style="padding-left: 16px; font-size: 18px">${params.percent}%</b></div>`
          },
        },
        legend: {
          bottom: 0,
          left: 'center',
          type: 'scroll',
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['50%', '45%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      chartData: [
        {
          category: '中国1',
          enCategory: 'china1',
          unit: 'USD',
          value: 200000.0,
        },
        {
          category: '中国2',
          enCategory: 'china2',
          unit: 'USD',
          value: 300000.0,
        },
        {
          category: '中国3',
          enCategory: 'china3',
          unit: 'USD',
          value: 300000.0,
        },
      ],
    }
  },
  created() {
    this.setOption()
  },
  methods: {
    setOption() {
      // this.chartData 为演示数据
      const chartData = this.chartInfo ? JSON.parse(this.chartInfo.data) : this.chartData

      // 设置series数据
      let seriesData = chartData.map((x) => {
        return {
          name: this.getText(x, 'category'),
          unit: x.unit,
          value: x.value,
        }
      })
      this.option.series[0].data = seriesData
    },
  },
}
</script>
