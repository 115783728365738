<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
let echarts = require('echarts/lib/echarts')
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart } from 'echarts/charts'
import { GridComponent, TooltipComponent, GraphicComponent, DataZoomComponent } from 'echarts/components'
import VChart from 'vue-echarts'

use([CanvasRenderer, BarChart, LineChart, GridComponent, TooltipComponent, GraphicComponent, DataZoomComponent])

export default {
  name: 'EchartBar',
  components: {
    VChart,
  },
  props: {
    chartInfo: Object,
  },
  provide: {},
  data() {
    return {
      option: {
        color: ['#64DAAB', '#FCAB4C', '#7666FA', '#62D5A7', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        grid: {
          top: 40,
          left: '14%',
          right: '14%',
          // bottom: 20,
        },
        dataZoom: [
          {
            height: 15,
          },
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            axisLabel: {
              formatter: '{value}',
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barMaxWidth: '10',
            data: [],
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' },
              ]),
            },
          },
          {
            name: '',
            type: 'line',
            symbol: 'none',
            smooth: true,
            yAxisIndex: 1,
            data: [],
          },
        ],
      },
      chartData: {
        name1: '自定义名称1',
        name2: '自定义名称2',
        values: [
          {
            x: '2020-01',
            value1: 10001.0,
            value2: 7003.0,
          },
          {
            x: '2020-02',
            value1: 14002.0,
            value2: 10004.0,
          },
          {
            x: '2020-03',
            value1: 16002.0,
            value2: 12004.0,
          },
          {
            x: '2020-04',
            value1: 25002.0,
            value2: 20004.0,
          },
        ],
      },
    }
  },
  created() {
    this.setOption()
  },
  methods: {
    setOption() {
      // this.chartData 为演示数据
      const chartData = this.chartInfo ? JSON.parse(this.chartInfo.data) : this.chartData

      // 设置Y轴最小值和最大值
      if (this.chartInfo && this.chartInfo.minY && this.chartInfo.maxY) {
        this.option.yAxis[0].min = this.chartInfo.minY
        this.option.yAxis[0].max = this.chartInfo.maxY
      }

      // 设置X轴数据
      let xAxis = chartData.values.map((x) => {
        return x.x
      })
      this.option.xAxis[0].data = xAxis

      // 设置series数据
      let series = this.option.series
      series[0].name = chartData.name1
      series[0].data = chartData.values.map((x) => {
        return x.value1
      })

      series[1].name = chartData.name2
      series[1].data = chartData.values.map((x) => {
        return x.value2
      })
      this.option.series = series
    },
  },
}
</script>
