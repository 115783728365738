<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import { GridComponent, TooltipComponent, DataZoomComponent } from 'echarts/components'
import VChart from 'vue-echarts'

use([CanvasRenderer, BarChart, GridComponent, TooltipComponent, DataZoomComponent])

export default {
  name: 'EchartBarStack',
  components: {
    VChart,
  },
  props: {
    chartInfo: Object,
  },
  provide: {},
  data() {
    return {
      option: {
        color: ['#0C60F7', '#FCAB4C', '#7666FA', '#62D5A7', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        grid: {
          top: 40,
          left: '14%',
          right: 40,
          // bottom: 70,
        },
        dataZoom: [
          {
            height: 15,
          },
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          bottom: '0',
          left: 'center',
          type: 'scroll',
        },
        barMaxWidth: '20',
        xAxis: [
          {
            type: 'category',
            data: [],
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [],
      },
      chartData: [
        {
          x: '2020-01',
          values: [
            {
              category: '分类A',
              value: 20001.0,
            },
            {
              category: '分类B',
              value: 20003.0,
            },
            {
              category: '分类C',
              value: 20005.0,
            },
          ],
        },
        {
          x: '2020-02',
          values: [
            {
              category: '分类A',
              value: 20002.0,
            },
            {
              category: '分类B',
              value: 20004.0,
            },
            {
              category: '分类C',
              value: 20006.0,
            },
          ],
        },
      ],
    }
  },
  created() {
    this.setOption()
  },
  methods: {
    setOption() {
      // this.chartData 为演示数据
      const chartData = this.chartInfo ? JSON.parse(this.chartInfo.data) : this.chartData

      // 设置Y轴最小值和最大值
      if (this.chartInfo && this.chartInfo.minY && this.chartInfo.maxY) {
        this.option.yAxis[0].min = this.chartInfo.minY
        this.option.yAxis[0].max = this.chartInfo.maxY
      }

      // 设置X轴数据
      let xAxis = chartData.map((x) => {
        return x.x
      })
      this.option.xAxis[0].data = xAxis

      // 设置series数据
      let series = []
      series = chartData[0].values.map((x) => {
        return {
          name: x.category,
          type: 'bar',
          stack: 'category',
          emphasis: {
            focus: 'series',
          },
          data: [],
        }
      })

      chartData.forEach((x) => {
        x.values.forEach((y) => {
          series.forEach((z) => {
            if (z.name === y.category) {
              z.data.push(y.value)
            }
          })
        })
      })

      this.option.series = series
    },
  },
}
</script>
