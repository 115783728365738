<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
let echarts = require('echarts/lib/echarts')
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import { GridComponent, TooltipComponent, DataZoomComponent } from 'echarts/components'
import VChart from 'vue-echarts'

use([CanvasRenderer, BarChart, GridComponent, TooltipComponent, DataZoomComponent])

export default {
  name: 'EchartBar',
  components: {
    VChart,
  },
  props: {
    chartInfo: Object,
  },
  provide: {},
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            const { value, unit } = params[0].data
            return `<div style="padding: 0 4px; font-size: 12px; color: #000"><div style="display: inline-block; width: 10px; height: 10px; margin-right: 6px; border-radius: 50%; background: #83bff6;"></div>${params[0].name}<div style="padding-left: 16px;">${value}</div></div>`
          },
        },
        grid: {
          top: 40,
          left: '14%',
          right: 40,
          // bottom: 20,
        },
        dataZoom: [
          {
            height: 15,
          },
        ],
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [],
            type: 'bar',
            barMaxWidth: '10',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' },
              ]),
            },
          },
        ],
      },
      chartData: [
        {
          x: '2020-01',
          unit: 'USD',
          value: 200000.0,
        },
        {
          x: '2020-02',
          unit: 'USD',
          value: 300000.0,
        },
      ],
    }
  },
  created() {
    this.setOption()
  },
  methods: {
    setOption() {
      // this.chartData 为演示数据
      const chartData = this.chartInfo ? JSON.parse(this.chartInfo.data) : this.chartData

      // 设置Y轴最小值和最大值
      if (this.chartInfo && this.chartInfo.minY && this.chartInfo.maxY) {
        this.option.yAxis.min = this.chartInfo.minY
        this.option.yAxis.max = this.chartInfo.maxY
      }

      // 设置X轴数据
      let xAxis = chartData.map((x) => {
        return x.x
      })
      this.option.xAxis.data = xAxis

      // 设置series数据
      let seriesData = chartData.map((x) => {
        return {
          unit: x.unit,
          value: x.value,
        }
      })
      this.option.series[0].data = seriesData
    },
  },
}
</script>
